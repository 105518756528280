import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/compare',
    name: 'comparepage',
    component: () => import('../views/ComparePage.vue')
  },
  {
    path: '/wishlist',
    name: 'wishlistpage',
    component: () => import('../views/WishlistPage.vue')
  },
  {
    path: '/my-account',
    name: 'my-accountpage',
    component: () => import('../views/AccountPage.vue')
  },
   {
    path: '/checkout',
    name: 'checkoutpage',
    component: () => import('../views/CheckoutPage.vue')
  },
  {
    path: '/login',
    name: 'Loginpage',
    component: () => import('../views/LoginPage.vue')
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
