<template>
	<div>
		<footer class="footer-area">
			<div class="footer-top">
				<div class="container">
					<div class="row">
						<!-- footer single wedget -->
						<div class="col-md-6 col-lg-4">
							<!-- footer logo -->
							<div class="footer-logo">
								<h4 class="footer-herading">Hiatianamazon</h4> 
							</div>
							<!-- footer logo -->
							<div class="about-footer">
								<p class="text-info">We are a team of designers and developers that create high quality HTML template</p>
								<div class="need-help">
									<p class="phone-info">
										NEED HELP?
										<span>
											(+880)1798333563 <br />
											
										</span>
									</p>
								</div>
								<div class="social-info">
									<ul>
										<li>
											<a href="#"><i class="ion-social-facebook"></i></a>
										</li>
										<li>
											<a href="#"><i class="ion-social-twitter"></i></a>
										</li>
										<li>
											<a href="#"><i class="ion-social-youtube"></i></a>
										</li>
										<li>
											<a href="#"><i class="ion-social-google"></i></a>
										</li>
										<li>
											<a href="#"><i class="ion-social-instagram"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<!-- footer single wedget -->
						<div class="col-md-6 col-lg-2 mt-res-sx-30px mt-res-md-30px">
							<div class="single-wedge">
								<h4 class="footer-herading">Information</h4>
								<div class="footer-links">
									<ul>
										<li><a href="#">Delivery</a></li>
										<li><a href="#">About Us</a></li>
										<li><a href="#">Secure Payment</a></li>
										<li><a href="#">Contact Us</a></li>
										<li><a href="#">Sitemap</a></li>
										<li><a href="#">Stores</a></li>
									</ul>
								</div>
							</div>
						</div>
						<!-- footer single wedget -->
						<div class="col-md-6 col-lg-2 mt-res-md-50px mt-res-sx-30px mt-res-md-30px">
							<div class="single-wedge">
								<h4 class="footer-herading">Custom Links</h4>
								<div class="footer-links">
									<ul>
										<li><a href="#">Legal Notice</a></li>
										<li><a href="#">Prices Drop</a></li>
										<li><a href="#">New Products</a></li>
										<li><a href="#">Best Sales</a></li>
										<li><a href="#">Login</a></li>
										<li><a href="#">My Account</a></li>
									</ul>
								</div>
							</div>
						</div>
						<!-- footer single wedget -->
						<div class="col-md-6 col-lg-4 mt-res-md-50px mt-res-sx-30px mt-res-md-30px">
							<div class="single-wedge">
								<h4 class="footer-herading">Newsletter</h4>
								<div class="subscrib-text">
									<p>You may unsubscribe at any moment. For that purpose, please find our contact info in the legal notice.</p>
								</div>
								<div id="mc_embed_signup" class="subscribe-form">
									<form
									id="mc-embedded-subscribe-form"
									class="validate"
									novalidate=""
									target="_blank"
									name="mc-embedded-subscribe-form"
									method="post"
									action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef"
									>
									<div id="mc_embed_signup_scroll" class="mc-form">
										<input class="email" type="email" required="" placeholder="Enter your email here.." name="EMAIL" value="" />
										<div class="mc-news" aria-hidden="true" style="position: absolute; left: -5000px;">
											<input type="text" value="" tabindex="-1" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef" />
										</div>
										<div class="clear">
											<input id="mc-embedded-subscribe" class="button" type="submit" name="subscribe" value="Sign Up" />
										</div>
									</div>
								</form>
							</div>
							<div class="img_app">
								<a href="#"><img src="assets/images/icons/app_store.png" alt="" /></a>
								<a href="#"><img src="assets/images/icons/google_play.png" alt="" /></a>
							</div>
						</div>
					</div>
					<!-- footer single wedget -->
				</div>
			</div>
		</div>
		<!--  Footer Bottom Area start -->
		<div class="footer-bottom">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-lg-4">
						<p class="copy-text">Copyright © <a href="#"> Hiatianamazon</a>. All Rights Reserved</p>
					</div>
					<div class="col-md-6 col-lg-8">
						<img class="payment-img" src="assets/images/icons/payment.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<!--  Footer Bottom Area End-->
	</footer>
	<!--  Footer Area End -->
</div>
</template>