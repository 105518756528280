<template>
	<header class="main-header">
		<div class="header-top-nav">
			<div class="container-fluid">
				<div class="row">
					<!--Left Start-->
					<div class="col-lg-4 col-md-4">
						<div class="left-text">
							<p>Welcome you to Hiatianamazon store!</p>
						</div>
					</div>
					<!--Left End-->
					<!--Right Start-->
					<div class="col-lg-8 col-md-8 text-right">
						<div class="header-right-nav">
							<ul class="res-xs-flex">
								<li class="after-n">
									<router-link to="/compare" ><i class="ion-ios-shuffle-strong"></i>Compare (0)</router-link>
								</li>
								<li>
									<router-link to="/wishlist"><i class="ion-android-favorite-outline"></i>Wishlist (0)</router-link>
								</li>
							</ul>
							<div class="dropdown-navs">
								<ul>
									<!-- Settings Start -->
									<li class="dropdown xs-after-n">
										<a class="angle-icon" href="#">Settings</a>
										<ul class="dropdown-nav">
											<li><router-link to="/my-account">My Account</router-link></li>
											<li><router-link to="/checkout">Checkout</router-link></li>
											<li><router-link to="/login">Login</router-link></li>
										</ul>
									</li>
									<!-- Settings End -->
									<!-- Currency Start -->
									<li class="top-10px first-child">
										<select>
											<option value="1">USD $</option>
											<option value="2">EUR €</option>
										</select>
									</li>
									<!-- Currency End -->
									<!-- Language Start -->
									<li class="top-10px mr-15px">
										<select>
											<option value="1">English</option>
											<option value="2">France</option>
										</select>
									</li>
									<!-- Language End -->
								</ul>
							</div>
						</div>
					</div>

					<!--Right End-->
				</div>
			</div>
		</div>
	</header>
</template>