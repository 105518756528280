<template>
<hedertop></hedertop>	
<headerbuttom>
<router-view v-slot="{Component}">
	<transition name="slide" mode="out-in">
		<component :is="Component"></component>
	</transition>
</router-view>
</headerbuttom>
<footer-section></footer-section>
</template>

<script>
import hedertop from './views/partils/HeaderTopView.vue';
import headerbuttom from './views/partils/HeaderButtomView.vue';
import FooterSection from './views/partils/FooterView.vue';
export default{
components: {
hedertop,
headerbuttom,
FooterSection

},
}
</script>


<style>
.slide-enter-from{
	opacity: 0;
	transform:translateY(100px);
}
.slide-enter-active{
	transition:all .5s ease-out;
}
.slide-enter-to{
	opacity: 0;
	transform: translateY(-100px);
}
.slide-enter-active{
	transition: all .5s ease-in;
}
</style>


