  <template>
        <header class="main-header">
            <div class="header-navigation sticky-nav">
                <div class="container-fluid">
                    <div class="row">
                        <!-- Logo Start -->
                        <div class="col-md-2 col-sm-2">
                            <div class="logo">
                                <div class="contact-link" style="margin-left:70px;">
                                    <div class="mylogo">
                                        <a style="position:relative;top:-5px!important">Hiatianamazon</a>

                                        <a style="position:relative;top:-10px!important"> market</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Logo End -->
                        <!-- Navigation Start -->
                        <div class="col-md-10 col-sm-10">
                            <!--Main Navigation Start -->
                            <div class="main-navigation d-none d-lg-block">
                                <ul>
                                    <li class="menu-dropdown">
                                        <router-link to="/">Home <i class="ion-ios-arrow-down"></i></router-link>
                                        <ul class="sub-menu">
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Home Organic <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="#">Organic 1</a></li>
                                                    <li><a href="#">Organic 2</a></li>
                                                    <li><a href="#">Organic 3</a></li>
                                                    <li><a href="#">Organic 4</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Home Cosmetic <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="#">Cosmetic 1</a></li>
                                                    <li><a href="#">Cosmetic 2</a></li>
                                                    <li><a href="#">Cosmetic 3</a></li>
                                                    <li><a href="#">Cosmetic 4</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Home Digital <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="#">Digital 1</a></li>
                                                    <li><a href="#">Digital 2</a></li>
                                                    <li><a href="#">Digital 3</a></li>
                                                    <li><a href="#">Digital 4</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Home Furniture <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="#">Furniture 1</a></li>
                                                    <li><a href="#">Furniture 2</a></li>
                                                    <li><a href="#">Furniture 3</a></li>
                                                    <li><a href="#">Furniture 4</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Home Medical <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="#">Medical 1</a></li>
                                                    <li><a href="#">Medical 2</a></li>
                                                    <li><a href="#">Medical 3</a></li>
                                                    <li><a href="#">Medical 4</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="menu-dropdown">
                                        <a href="#">Shop <i class="ion-ios-arrow-down"></i></a>
                                        <ul class="mega-menu-wrap">
                                            <li>
                                                <ul>
                                                    <li class="mega-menu-title"><a href="#">Shop Grid</a></li>
                                                    <li><a href="#">Shop Grid 3 Column</a></li>
                                                    <li><a href="#">Shop Grid 4 Column</a></li>
                                                    <li><a href="#">Shop Grid Left Sidebar</a></li>
                                                    <li><a href="#">Shop Grid Right Sidebar</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                    <li class="mega-menu-title"><a href="#">Shop List</a></li>
                                                    <li><a href="#">Shop List</a></li>
                                                    <li><a href="#">Shop List Left Sidebar</a></li>
                                                    <li><a href="#">Shop List Right Sidebar</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                    <li class="mega-menu-title"><a href="#">Shop Single</a></li>
                                                    <li><a href="#">Shop Single</a></li>
                                                    <li><a href="#">Shop Variable</a></li>
                                                    <li><a href="#">Shop Affiliate</a></li>
                                                    <li><a href="#">Shop Group</a></li>
                                                    <li><a href="#">Shop Tab 2</a></li>
                                                    <li><a href="#">Shop Tab 3</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                    <li class="mega-menu-title"><a href="#">Shop Single</a></li>
                                                    <li><a href="#">Shop Slider</a></li>
                                                    <li><a href="#">Shop Gallery Left</a></li>
                                                    <li><a href="#">Shop Gallery Right</a></li>
                                                    <li><a href="#">Shop Sticky Left</a></li>
                                                    <li><a href="#">Shop Sticky Right</a></li>
                                                </ul>
                                            </li>
                                            <li class="banner-wrapper">
                                                <a href="#"><img src="assets/images/banner-image/banner-menu.jpg" alt="" /></a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="menu-dropdown">
                                        <a href="#">Pages <i class="ion-ios-arrow-down"></i></a>
                                        <ul class="sub-menu">
                                            <li><a href="#">About Page</a></li>
                                            <li><a href="#">Cart Page</a></li>
                                            <li><a href="#">Checkout Page</a></li>
                                            <li><a href="#">Compare Page</a></li>
                                            <li><a href="#">Login & Regiter Page</a></li>
                                            <li><a href="#">Account Page</a></li>
                                            <li><a href="#">Wishlist Page</a></li>
                                        </ul>
                                    </li>
                                    <li class="menu-dropdown">
                                        <a href="#">Blog <i class="ion-ios-arrow-down"></i></a>
                                        <ul class="sub-menu">
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Blog Grid <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="blog-grid-left-sidebar.html">Blog Grid Left Sidebar</a></li>
                                                    <li><a href="blog-grid-right-sidebar.html">Blog Grid Right Sidebar</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Blog List <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="blog-list-left-sidebar.html">Blog List Left Sidebar</a></li>
                                                    <li><a href="blog-list-right-sidebar.html">Blog List Right Sidebar</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-dropdown position-static">
                                                <a href="#">Blog Single <i class="ion-ios-arrow-down"></i></a>
                                                <ul class="sub-menu sub-menu-2">
                                                    <li><a href="blog-single-left-sidebar.html">Blog Single Left Sidebar</a></li>
                                                    <li><a href="blog-single-right-sidebar.html">Blog Single Right Sidebar</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Contact Us</a></li>
                                </ul>
                            </div>
                            <!--Main Navigation End -->
                            <!--Header Bottom Account Start -->
                            <div class="header_account_area">
                                <!--Seach Area Start -->
                                <div class="header_account_list search_list">
                                    <a href="javascript:void(0)"><i class="ion-ios-search-strong"></i></a>
                                    <div class="dropdown_search">
                                        <form action="#">
                                            <input placeholder="Search entire store here ..." type="text" />
                                            <div class="search-category">
                                                <select class="bootstrap-select" name="poscats">
                                                    <option value="0">All categories</option>
                                                    <option value="104">
                                                        Fresh Food
                                                    </option>
                                                    <option value="183">
                                                        - - Fresh Fruit
                                                    </option>
                                                    <option value="187">
                                                        - - - - Bananas
                                                    </option>
                                                    <option value="188">
                                                        - - - - Apples &amp; Pears
                                                    </option>
                                                    <option value="189">
                                                        - - - - Berries &amp; Cherries
                                                    </option>
                                                    <option value="190">
                                                        - - - - Oranges &amp; Citrus Fruit
                                                    </option>
                                                    <option value="191">
                                                        - - - - Grapes
                                                    </option>
                                                    <option value="184">
                                                        - - Fresh Vegetables
                                                    </option>
                                                    <option value="192">
                                                        - - - - Potatoes &amp; Sweet Potatoes
                                                    </option>
                                                    <option value="193">
                                                        - - - - Onions &amp; Leeks
                                                    </option>
                                                    <option value="194">
                                                        - - - - Carrots &amp; Root Vegetables
                                                    </option>
                                                    <option value="195">
                                                        - - - - Broccoli &amp; Cauliflower
                                                    </option>
                                                    <option value="196">
                                                        - - - - Cabbages &amp; Greens
                                                    </option>
                                                    <option value="185">
                                                        - - Fresh Salad &amp; Dips
                                                    </option>
                                                    <option value="197">
                                                        - - - - Lettuce &amp; Salad bags
                                                    </option>
                                                    <option value="198">
                                                        - - - - Tomatoes
                                                    </option>
                                                    <option value="199">
                                                        - - - - Cucumber
                                                    </option>
                                                    <option value="200">
                                                        - - - - Celery
                                                    </option>
                                                    <option value="201">
                                                        - - - - Peppers
                                                    </option>
                                                    <option value="186">
                                                        - - Milk, Butter &amp; Eggs
                                                    </option>
                                                    <option value="202">
                                                        - - - - Milk
                                                    </option>
                                                    <option value="203">
                                                        - - - - Spreads &amp; Margarine
                                                    </option>
                                                    <option value="204">
                                                        - - - - Fresh Cream &amp; Custard
                                                    </option>
                                                    <option value="205">
                                                        - - - - Eggs
                                                    </option>
                                                    <option value="206">
                                                        - - - - Baking &amp; Cooking
                                                    </option>
                                                </select>
                                            </div>
                                            <button type="submit"><i class="ion-ios-search-strong"></i></button>
                                        </form>
                                    </div>
                                </div>
                                <!--Seach Area End -->
                                <!--Contact info Start -->
                                <div class="contact-link">
                                    <div class="phone">
                                        <p>Call us:</p>
                                        <a href="tel:(+800)345678">(+880)179833563</a>
                                    </div>
                                </div>
                                <!--Contact info End -->
                                <!--Cart info Start -->
                                <div class="cart-info d-flex">
                                    <div class="mini-cart-warp">
                                        <a href="#" class="count-cart"><span>$20.00</span></a>
                                        <div class="mini-cart-content">
                                            <ul>
                                                <li class="single-shopping-cart">
                                                    <div class="shopping-cart-img">
                                                        <a href="single-product.html"><img alt="" src="assets/images/product-image/mini-cart/1.jpg" /></a>
                                                        <span class="product-quantity">1x</span>
                                                    </div>
                                                    <div class="shopping-cart-title">
                                                        <h4><a href="single-product.html">Juicy Couture...</a></h4>
                                                        <span>$9.00</span>
                                                        <div class="shopping-cart-delete">
                                                            <a href="#"><i class="ion-android-cancel"></i></a>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="single-shopping-cart">
                                                    <div class="shopping-cart-img">
                                                        <a href="single-product.html"><img alt="" src="assets/images/product-image/mini-cart/2.jpg" /></a>
                                                        <span class="product-quantity">1x</span>
                                                    </div>
                                                    <div class="shopping-cart-title">
                                                        <h4><a href="single-product.html">Water and Wind...</a></h4>
                                                        <span>$11.00</span>
                                                        <div class="shopping-cart-delete">
                                                            <a href="#"><i class="ion-android-cancel"></i></a>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="shopping-cart-total">
                                                <h4>Subtotal : <span>$20.00</span></h4>
                                                <h4>Shipping : <span>$7.00</span></h4>
                                                <h4>Taxes : <span>$0.00</span></h4>
                                                <h4 class="shop-total">Total : <span>$27.00</span></h4>
                                            </div>
                                            <div class="shopping-cart-btn text-center">
                                                <a class="default-btn" href="checkout.html">checkout</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Cart info End -->
                            </div>
                        </div>
                    </div>
                    <!-- mobile menu -->
                    <div class="mobile-menu-area">
                        <div class="mobile-menu">
                            <nav id="mobile-menu-active">
                                <ul class="menu-overflow">
                                    <li>
                                        <a href="index.html">HOME</a>
                                        <ul>
                                            <li>
                                                <a href="#">Home Organic</a>
                                                <ul>
                                                    <li><a href="#">Organic 1</a></li>
                                                    <li><a href="#">Organic 2</a></li>
                                                    <li><a href="#">Organic 3</a></li>
                                                    <li><a href="#">Organic 4</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">Home Cosmetic</a>
                                                <ul>
                                                    <li><a href="#">Cosmetic 1</a></li>
                                                    <li><a href="#">Cosmetic 2</a></li>
                                                    <li><a href="#">Cosmetic 3</a></li>
                                                    <li><a href="#">Cosmetic 4</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">Home Digital</a>
                                                <ul>
                                                    <li><a href="#">Digital 1</a></li>
                                                    <li><a href="#">Digital 2</a></li>
                                                    <li><a href="#">Digital 3</a></li>
                                                    <li><a href="#">Digital 4</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">Home Furniture</a>
                                                <ul>
                                                    <li><a href="#">Furniture 1</a></li>
                                                    <li><a href="#">Furniture 2</a></li>
                                                    <li><a href="#">Furniture 3</a></li>
                                                    <li><a href="#">Furniture 4</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">Home Medical</a>
                                                <ul>
                                                    <li><a href="#">Medical 1</a></li>
                                                    <li><a href="#">Medical 2</a></li>
                                                    <li><a href="#">Medical 3</a></li>
                                                    <li><a href="#">Medical 4</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">Shop</a>
                                        <ul>
                                            <li>
                                                <a href="#">Shop Grid</a>
                                                <ul>
                                                    <li><a href="#">Shop Grid 3 Column</a></li>
                                                    <li><a href="#">Shop Grid 4 Column</a></li>
                                                    <li><a href="#">Shop Grid Left Sidebar</a></li>
                                                    <li><a href="#">Shop Grid Right Sidebar</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">Shop List</a>
                                                <ul>
                                                    <li><a href="#">Shop List</a></li>
                                                    <li><a href="#">Shop List Left Sidebar</a></li>
                                                    <li><a href="#">Shop List Right Sidebar</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">Single Shop</a>
                                                <ul>
                                                    <li><a href="#">Shop Single</a></li>
                                                    <li><a href="#">Shop Variable</a></li>
                                                    <li><a href="#">Shop Affiliate</a></li>
                                                    <li><a href="#">Shop Group</a></li>
                                                    <li><a href="#">Shop Tab 2</a></li>
                                                    <li><a href="#">Shop Tab 3</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="#">Single Shop</a>
                                                <ul>
                                                    <li><a href="#">Shop Slider</a></li>
                                                    <li><a href="#">Shop Gallery Left</a></li>
                                                    <li><a href="#">Shop Gallery Right</a></li>
                                                    <li><a href="#">Shop Sticky Left</a></li>
                                                    <li><a href="#">Shop Sticky Right</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">Pages</a>
                                        <ul>
                                            <li><a href="#">About Page</a></li>
                                            <li><a href="#">Cart Page</a></li>
                                            <li><a href="#">Checkout Page</a></li>
                                            <li><a href="#">Compare Page</a></li>
                                            <li><a href="#">Login & Regiter Page</a></li>
                                            <li><a href="#">Account Page</a></li>
                                            <li><a href="#">Wishlist Page</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">Blog</a>
                                        <ul>
                                            <li><a href="#">Blog Grid Left Sidebar</a></li>
                                            <li><a href="#">Blog Grid Right Sidebar</a></li>
                                            <li><a href="#">Blog List Left Sidebar</a></li>
                                            <li><a href="#">Blog List Right Sidebar</a></li>
                                            <li><a href="#">Blog Single Left Sidebar</a></li>
                                            <li><a href="#">Blog Single Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Contact Us</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <!-- mobile menu end-->
                </div>

            </div>
              <router-view/>
        </header>
</template>